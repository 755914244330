export const card = {
  marginTop: '25px',
  padding: '10px',
  width: '98%',
};
export const customerData = {
  padding: '5px',
};

export const customerDetailButtonBox = {
  justifyContent: 'space-between',
};
